import React from 'react';
import Img from 'gatsby-image';

const Image = ({ image, ...theRest }) => {
  if (!image) {
    return null;
  }
  if (image.extension === 'gif') {
    return <img src={ image.url } alt="img gif" { ...theRest } />;
  }

  if (image.childImageSharp && image.childImageSharp.fluid) {
    return <Img fluid={ image.childImageSharp.fluid } { ...theRest } />;
  }

  if (image.childImageSharp && image.childImageSharp.fixed) {
    return <Img fixed={ image.childImageSharp.fixed } { ...theRest } />;
  }

  return null;
};

export default Image;
