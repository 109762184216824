import React from 'react';

import Image from '../Image';
import kaustLogo from '../../images/kaust-logo.svg';

const PostThumbnail = ({ thumbnail }) => {
  if (!thumbnail || !thumbnail.localFiles) {
    return (
      <div className="teaser__thumbnail">
        <img
          alt="Kaust logo"
          className="teaser__thumbnail__default"
          src={ kaustLogo }
        />
      </div>
    );
  }

  const imgData = thumbnail.localFiles[0];

  return (
    <div className="teaser__thumbnail">
      <Image image={ imgData } />
    </div>
  );
};

export default PostThumbnail;
